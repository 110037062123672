/*
This is where you write custom SASS
*/

.office-headers span {
    opacity: .6;
    cursor: pointer;
    color: #333 !important;
    &.active {
        opacity: 1;
    }
    &:hover, &:focus {
        outline: 3px dashed saturate(lighten($primary,30%),50%) !important;
    }
}

* input[type=checkbox]:focus + label, 
* input[type=radio]:focus + label,
* input[type=checkbox] + label:focus, 
* input[type=radio] + label:focus,
* a:focus,
* button:focus,
* input:focus,
* textarea:focus,
* input[type=checkbox]:hover + label, 
* input[type=radio]:hover + label,
* input[type=checkbox] + label:hover, 
* input[type=radio] + label:hover,
* a:hover,
* button:hover,
* input:hover,
* textarea:hover {
    outline: 3px dashed saturate(lighten($primary,30%),50%) !important;
}
::placeholder {
    font-family: 'brother-1816', sans-serif;
}

.form-control {
    font-family: 'brother-1816', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

.text-initial {
    text-transform: initial;
}

.backdrop-blur {
  background-color: rgba(233, 233, 233, 0.9);
}

@supports ((-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))) {
  .backdrop-blur {
    background: rgba(245,245,245,0.75);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 615px;
        min-height: 400px;
        @media screen and (max-width: 1600px) {
            & {
                height: 40vw;
            }
        }
    }
    &.page-type-3 {
        .card {
            padding: 60px 55px;
            background: linear-gradient(#f3f0eb 0%, #efe9e2 100%);
            p.h3 {
                text-transform: uppercase;
                color:  $gray-800
            }
            [id*="HeaderInfoDiv"] {
                font-size: 18px;
                font-weight: 300;
                color: $gray-800;
                line-height: 30px;
            
            }
            label {
                display: none;
            }
        }
    }
    &.page-type-26 {
        .page-content .container {
            max-width: 960px;
        }
    }
}
.hero-section .owl-stage {
    transform: none !important;
}

.hero-section .owl-stage .owl-item {
    transition: 1s ease 0.5s;
    opacity: 0;
}
.hero-section .owl-stage .owl-item.active {
    transition: 1s ease 0s;
    opacity: 1;
}

.hero-section .owl-stage .owl-item:not(:first-child) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.page-type-40 {
    .selectstarnote {
        padding-left: 0 !important;
        font-size: 36px; 
        font-weight: bold;
        margin: 0 0 10px 0;
    }
    .jq-ry-group-wrapper {
        display: none;
    }
    .textarea_feedback {
        background: #131313;
        color: #e6e6e6;
        border: 1px solid #131313;
        border-radius: 2px;
        padding: 4px 8px;
        font-size: 12px;
        display: inline-block;
    }
}

.page-type-43 {
    [style*="color: #1dac9d"] {
        color: #564048 !important;
    }
    a#ctl00_ContentPlaceHolder1_ApplyNowID1 {
        color: #fff !important;
        background-color: #564048 !important;
        border-color: #564048 !important;
    }
    a#ctl00_ContentPlaceHolder1_ApplyNowID2 {
        background-color: #564048 !important;
        border-color: #564048 !important;
    }
}